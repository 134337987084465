const PLAY = [
  // {
  //   img: 'cookie.JPG',
  //   title: 'My cat, Cookie',
  // },
  {
    img: 'osaka.jpg',
    title: 'osaka lanterns',
  },
  {
    img: 'tokyo1.jpg',
    title: 'tokyo clothes',
  },
  {
    img: 'phoebe.JPG',
    title: 'Phoebe Bridgers portrait 1',
  },
  {
    img: 'sugar.JPG',
    title: 'Sugar',
  },
  {
    img: 'hk1.jpg',
    title: 'hong kong',
  },
  {
    img: 'grandma.JPG',
    title: 'Grandma',
  },
  {
    img: 'grandpa.JPG',
    title: 'Grandpa',
  },
  {
    img: 'shanghai.jpg',
    title: 'shanghai',
  },
  {
    img: 'jj_nans.jpg',
    title: 'Jessica & Nandini',
  },
  {
    img: 'seoul_bike.jpg',
    title: 'eunpyong village bike',
  },
  {
    img: 'turf2.JPG',
    title: 'Turf valley winter 2',
  },
  // {
  //   img: 'les.jpg',
  //   title: 'Lower East Side',
  // },
  {
    img: 'malavika-skate.jpg',
    title: 'Malavika at the skate park',
  },
  {
    img: 'malavika-suz.jpg',
    title: 'Malavika and Juliana learning to skate',
  },
  {
    img: 'mom.JPG',
    title: 'Mom and Cookie',
  },
  {
    img: 'hanok_village.jpg',
    title: 'hanok village, seoul',
  },
  // {
  //   img: 'brooklyn-bridge.jpg',
  //   title: 'Friends at brooklyn bridge',
  // },
  // {
  //   img: 'portrait_painting.jpg',
  //   title: 'Portrait beach scene',
  // },
  // {
  //   img: "portrait-1.JPG",
  //   title: "Self portrait 1",
  // },
  // {
  //   img: "phoebe-2.JPG",
  //   title: "Phoebe Bridgers portrait 2",
  // },
  {
    img: 'sunset.jpg',
    title: 'Malavika at sunset',
  },
  // {
  //   img: 'suz_gloria.JPG',
  //   title: 'Juliana and Gloria in NY',
  // },
  // {
  //   img: 'suz-malu.jpg',
  //   title: 'Juliana & malu skate park portraits',
  // },
  // {
  //   img: 'suz-museum.jpg',
  //   title: 'Juliana at the Whitney',
  // },
  {
    img: 'suz-skate.jpg',
    title: 'Juliana skateboarding',
  },
  {
    img: 'daidaipeng.jpg',
    title: 'dai dai peng',
  },
  {
    img: 'mountain.jpg',
    title: 'Seoul mountain',
  },
  {
    img: 'suz-sriya.jpg',
    title: 'Juliana & Sriya',
  },
  {
    img: 'toucan.jpg',
    title: 'Toucan painting',
  },
  // {
  //   img: 'portrait-2.JPG',
  //   title: 'Self portrait 2',
  // },
  // {
  //   img: 'suz.JPG',
  //   title: 'Juliana at skate park again',
  // },
  {
    img: 'turf1.jpg',
    title: 'Turf valley winter',
  },
];
export default PLAY;
